.widthTablePunten {
    width: 54vw !important;
  }
  
.viewCustomer > .table-container > table {
  width: 47vw !important;
}

.streetwidth {
  width: 24.5vw !important;
}

.twoInputFieldWidth {
  width: 19.6vw ;
}

.businesNumbers {
  width: 40.5vw;
}

@media only screen and (max-width: 1124px) {
  .twoInputFieldWidth {
    width: 21.3vw ;
  }

  .businesNumbers {
    width: 43.5vw;
  }
  
}