.navbar {
  position: fixed !important;
  width: 87.5%;
  left: 13%;
  z-index: 	2;
}

.profilePicture {
  width: 48px !important;
  height: 48px !important;
  position: relative;
  bottom: 12px;
  right: 5px;
  border: 2px solid rgb(233 233 233);
}

.warehouseBox {
  width: 60px;
  height: 60px;
  color: white;
  background-color: #79b893;
}

.searchbarNav {
  border: none;
  width: 500px;
  position: relative;
}

.searchbarNav {
  outline: none;
}

.warehouseIcon {
  width: 60%;
  margin-left: 0.55rem ;
  margin-top: 0.50rem ;
  height: 60%;
}

.iconsNavQuestionMark {
  width: 25px;
  height: 25px;
}

.searchbar {
  border: none;
}

.searchbarNav::placeholder {
  border: none;
  font-weight: 500;
  color: #000000;
}

.searchbar:focus {
  outline: none;
}

.iconHeightNav {
  padding: 0 !important;
}

.iconHeightNavDash {
  padding-top: 1.25rem;
}

.iconsNavBell {
  width: 25px;
  height: 25px;
}

.iconsNav {
  width: 15px;
  height: 15px;
}

.iconBox {
  position: relative; 
  top: 5px;
 }


.firstItem{
  margin-top: 0px !important;
}


.pictureWerknemer {
  padding-top: 0px !important;
  background-color: #b4b4b4;
  height: 45px;
  width: 45px;
  position: relative;
  bottom: 10px;
}

.dot {
  position: relative;
}

.dot::after {
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.dropdownNav {
  position: relative;
  display: inline-block;
  height: 35px;
  border-left:2px solid rgb(233 233 233);
}

.dropdownNavDash {
  position: relative;
  display: inline-block;
  height: 35px;
}

/**/
.userInfoMenu{
 position:relative !important;
 top: 5px;
 z-index: 999 !important;
}

.profile-container {
  display: flex;
  align-items: center;
}


.link {
  text-decoration: none;
  color: gray;
}

.link:hover {
  text-decoration: none;
  color: rgb(99, 99, 99);
}

.logOut {
  color: #414141;
  position: absolute;
  top: 272px;
  right: 20px;
  text-decoration: none;
}

.logOut:hover {
  color: #888888;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 15px;
  bottom: -250px;
  right: -15px;
  padding-left: 2rem;
  height: 300px;
  width: auto;
  z-index: 998 !important;
  background-color: white;
  box-shadow: 3px 3px 5px 1px rgb(180, 180, 180);
  transition: opacity 0.5s;
}

.userInfoMenu ~ .dropdown-content,
.dropdown-content:hover {
  display: flex;
  flex-direction: column;
}

.dropdownIndicator {
  position: relative;
  top: 4px;
  left: 5px;
}


.dropdownNav.clicked .dropdownIndicator {
  animation: spin 0.5s forwards;
}

.dropdownNav:not(.clicked) .dropdownIndicator {
  animation: spin-back 0.5s forwards;
}

.hover-area {
  display: none;
  position: absolute;
  height: 50px;
  width: 100%;
  top: -50px;
  left: 0;
  z-index: 997;
}
.userInfoMenu:hover ~ .hover-area {
  display: block;
}

.line {
  width: 100%;
  position: absolute;
  right: 0px;
  top: 270px;
  padding-left: 5vw !important	;
  border-bottom: 1px solid #d4d4d4;
}

.dropdownListedItems {
  position: relative; 
  top: 80px;
}

.links {
  text-decoration: none;
  margin-bottom: 25px !important;
}


/* @media only screen and (max-width: 999px) {
  .navbar {
      display: flex;
      flex-direction: column !important;
  }

  .iconHeightNav {
    display: flex;
    padding-top: 1.5rem !important;
}
} */


@media only screen and (max-width: 550px) {

  .Werknemer {
      border-left: 0px solid #000000;
      height: 30px;
      margin-top: 1rem;
      padding-left: 0px !important;
      margin-left: 0px !important;
   }

   .iconHeightNav {
      display: flex;
      flex-direction: column;
      padding-top: 1rem !important;
  }

  .iconHeightNavDash {
    display: flex;
    flex-direction: column;
    padding-top: 1rem !important;
    padding-left: 0px !important;
}

  .dropdownNav {
    position: relative;
    display: inline-block;
    height: 35px;
    border-left: 0px solid #000000 !important;
  }

  .iconsNavBell {
     margin-right: 0px !important;
  }

  .iconBox {
      margin: 0px !important;
      display: flex;
      justify-content: center;
      margin-bottom: 25px !important;
  }

}

@media only screen and (max-width: 550px) {
  .navbar {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 2px;
}

.warehouseIcon {
    margin-right: 1rem !important;
}
.pageTitle {
    font-size: medium;
    padding-top: 1.5rem !important;
}
}


/* The container <div> - needed to position the dropdown content */


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes spin-back {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}


/* Animations */
@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes fadeOut{
  0% {opacity: 1;}
  100% {opacity: 0;}
}
