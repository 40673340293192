.buttonsModal {
    position: relative;
    top: 30px;
}

.buttonColor:hover {
    background-color: #0aafc2;
    border: none;
}

.buttonColor:active {
    background-color: #0bbdd1 !important;
    color: white !important;
    border: none;
}

.buttonColor {
    background-color: #0bc0d5;
    border: none;
}

.modal-header {
  border-bottom: transparent;
}

  
  .closeModal {
    color: rgb(0, 0, 0);
    height: 40px;
    width: 40px;
    display: flex;
  }
  
  .contendModal {
    position: relative;
    bottom: 40px;
    margin-left: 20px;
  }

  .searchCustomer {
    border: 2px solid #efefef;
    border-radius: 30px;
    width: 30vw;
    height: 40px;
  }

  .searchCustomer:focus {
    border: 2px solid #efefef;
    outline: none;
  }

  .searchCustomer::placeholder {
    color: black;
  }

  .imgSearch {
    position: relative;
    top: 8px !important;
  }

  .iconLineHeight {
    margin-bottom: 3px;
    position: relative;
    right: 30px;
}

.infoTitles {
    margin-right: 100px;
}

.textRow {
    width: 20vw;
}

.connectPas {
    color: white;
    position: relative;
}

.connectPas:hover {
    color: white;
    position: relative;
}


.textPlace {
    width: 200px;
}

.iconChangePasNum {
    height: 19px;
    margin-top: 2px;
}

/* @media only screen and (max-width: 1650px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 15vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1550px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 13vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1450px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 9vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 7vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 3vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1250px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: 0vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1150px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -5vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -6vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1050px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -11vw !important;
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -14vw !important;
    display: none;
  }
}

@media only screen and (max-width: 950px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -17vw !important;
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 1000px;
    left: -21vw !important;
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .modal {
    border-radius: 20px;
    position: absolute;
    top: 20vh;
    width: 800px;
    left: 13vw !important;
    display: none;
  }

  .modal-header {
    width: 500px !important;
  }

  .modal-body {
    width: 500px !important;
  }

} */




