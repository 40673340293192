/* option:nth-child(even) {
    background-color: rgb(245, 245, 245) !important;
} 
option {
    justify-content: center;
} */

/* tr:nth-child(even) {
    background-color: rgb(236, 236, 236) !important;
} */

.table-container {
  background-color: white;
  padding: 30px;
  padding-bottom: 4px;
  margin-bottom: 5px;
  /* margin: 10px; */
  border-radius: 20px;
  /* border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; */
}

.table-containerCustomer {
  background-color: white;
  padding: 30px;
  /* margin: 10px; */
  border-radius: 40px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

table {
  border-radius: 0px;
  margin-bottom: 20px;
  width: 70vw;
  margin-left: 20px;
  margin-right: 20px;
  /* border-bottom: 1px solid lightgray; */
}

.page-display {
  /* cursor: crosshair !important; */
  align-self: center;
  margin-left: 7px;
  font-weight: 500;
  color: #9d9d9d;
  min-width: 20px;
}

tr:hover {
    transition: .1s;
    background-color: #d8e8ff;
}

thead > tr {
  background-color: white !important;
  border-bottom: 2px solid rgb(189, 189, 189);
  font-weight: 100 !important;
}

.header-filter:hover {
  cursor: pointer;
}

tbody > tr {
    font-size: 15px;
    font-weight: 500;
}


tr:last-child > td, tr:last-child {
  padding-bottom: 0px;
}

/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
    border: 1px solid #9d9d9d;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  /* .checkbox-container input:checked ~ .checkmark { */
    /* background-color: #2196F3; */
    /* border: none; */
  /* } */
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 8px;
    top: 2px;
    width: 8px;
    height: 16px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg) scale(1.3);
  }

.btn-custom {
    /* border: 2px solid #9d9d9d; */
    padding: 10px;
    border-radius: 200px;
    font-weight: 600;
    display: flex;
    align-items: center;
    /* width: 100px; */
    height: 40px;
    margin-right: 0px !important;
    margin: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    height: 40px;
    outline: none;
}
.btn-custom:hover {
    transition: 200ms;
    background-color: rgb(216, 216, 216);
    text-decoration: none;
    color: black;
}

.btn-custom2, .btn-custom2:focus {
    border: 1px solid #9d9d9d;
    padding: 10px;
    /* width: 100px; */
    height: 40px;
    font-weight: 600;

    align-items: center !important;
    padding-bottom: 10px !important;
    display: flex !important;
    margin-left: 0px !important;
    margin: 20px;
    background-color: white;
    text-decoration: none;
    color: black;
    outline: none;
}

.btn-custom2:hover {
    transition: 200ms;
    background-color: rgb(216, 216, 216);
}


.btn:disabled {
    /* background-color: black; */
    color: #c5c5c5;
}

.page-sizer {
  display: flex;
  height: 30px;
  border-radius: 20px;
  border: 0px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  /* width: 100px; */
  align-content: center;
  align-self: center;
  border-right: 6px solid #00000000;
  font-weight: 600;
  /* transform: translateY(-4px); */
  /* font-size: 20px !important; */
}



select > option {
  background-color: white !important;
}

.btn-custom3 {
  background-color: white;
  height: 40px;
  color: #9d9d9d;
  display: flex;
  align-self: center;
  border: 1px solid #9d9d9d;
  padding: 10px;
  align-items: center;
}

.btn-custom3 > input:focus, .btn-custom3 > input {
  outline: none;
  font-weight: 600 !important;
  background-color: white;
}

/* .modal {
  position: absolute;
  left: -200px !important;
  top: 10vh;
  border-radius: 20px;
}

.modal-header {
  border-bottom: none;
  width: 50vw;
  justify-content: end;
  padding: 0;
  background-color: #c0c0c0;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.modal-body {
  height: 500px;
  width: 50vw;
  padding: 30px;
  background-color: #c0c0c0;
}

.modal-footer {
  border-top: none;
  width: 50vw;
  background-color: #c0c0c0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.closeModal {
  color: white;
  height: 60px;
  width: 60px;
  display: flex;
}

.modal-content {
  background-color: transparent;
  border: none;
}

.contendModal {
  position: relative;
  bottom: 40px;
  margin-left: 20px;

} */

.dropdownStyle {
  background-color: #dbdbdb;
  width: 30vw !important;
}

.buttonStyle {
  border-radius: 50px;
  height: 30px;
  line-height: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.itemsModalSearch {
  word-wrap: break-word;
}


/* 
table {
    padding: 20px;
} */