.heightCustomerSearchBig {
  min-height: 431px;
  height: max-content;
  margin-top: 20px;
  width: 66%;
}

.radioLineHeight {
  margin-top: 7.5px;
}

.heightCustomerInfo {
  height: inherit;
  margin-top: 20px;
  width: 30% !important;
  /* min-width: 325px; */
}

.boxNieuwClient {
  width: 53vw;
}

.addClientButton {
  background-color: #0bc0d5;
  border-radius: 50px;
  color: white;
  width: 13vw;
  min-width: fit-content;
}

.widthStreetName {
  width: 25vw !important;
}

.addClientButton:hover, .addClientButton:focus, .addClientButton:active {
  background-color: #0bb1c4 !important;
  color: white !important;
  outline: none !important;
  width: 13vw;
}

.inputFieldWidthStreet {
  width: 25.5vw;
}

.heightCustomerNote {
  height: 30vh;
  margin-top: 20px;
  width: 30%;
  padding-bottom: 40px !important;
}

.parentBoxes {
  width: 80vw;
}

.tableNote {
  padding-bottom: 50px;
}

.iconLineHeight {
  position: relative;
  right: 30px;
  margin-top: 10px !important;
}

.inputFieldWidth {
  width: 93%;
}

.tableInputFieldWidth {
  width: 60%;
}

.tableRowInputFieldWidth {
  border-radius: 100px !important;
}

.inputFieldWidth::placeholder {
  color: black;
  font-weight: 500;
}

.balance {
  height: 45px;
  width: 10vw;
  padding-left: 0px;
  border:2px solid rgb(194 194 194);
  background-color: rgb(233 233 233);
  font-size: large;
}

.balance::placeholder {
  color: black;
}

.inputAddRemove {
  width: 250px;
}

.dropdownReason {
  width: 240px;
  padding-right: 40px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropdownArrow {
  position: relative;
  right: 45px !important;
}

.heightInputs {
  height: 40px;
}

.addBalance {
  background-color: #01ac07;
  color: white;
  border-radius: 30px;
  width: 20%;
}

.widthTablePunten {
  width: 54vw !important;
}

.titleTableViewCustomers {
  position: relative;
  top: 25px;
  padding-left: 30px;
  padding-top: 20px;
}

.customerTable > .table-container > table {
  width: 77vw !important;
}


.viewCustomer > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(1)  {
  width: 100px !important;
  margin: 0px !important;
}

.viewCustomer > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(4)  {
  width: 200px !important;
  margin: 0px !important;
}

.viewCustomer > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(1)  {
  width: 200px !important;
  margin: 0px !important;
  flex-wrap: nowrap;
}

.viewCustomer > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(3)  {
  width: 50px !important;
  margin: 0px !important;
}

.addBalance:hover {
  background-color: #029607;
  color: white;
}

.addBalance:active {
  background-color: #029607 !important;
  color: white !important;
}

.removeBalance {
  background-color: #ff4343;
  color: white;
  border-radius: 30px;
  width: 20%;
}

.removeBalance:hover {
  background-color: #e03939;
  color: white;
}


.makeCustomer {
  margin-bottom: 60px;
}


.removeBalance:active {
  background-color: #e03939 !important;
  color: white !important;
}

.textBold {
  font-weight: 500;
}

.birthDay {
  height: 20px;
}

.cakeIcon {
  height: 32px;
  margin-top: 7px;
}

.textareaStyle {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  resize: none;
  height: 20vh;
  width: 90%;
}

.viewCustomer > .table-container > table.dataTable > thead.customThead > tr > th:nth-child(3)  {
  white-space: nowrap !important;
}

.searchConsumer {
  width: 80vw;
}

@media only screen and (max-width: 1200px) {
  .heightCustomerInfo {
    height: inherit;
    margin-top: 20px;
    width: 30% !important;
    margin-right: 10px;
    /* min-width: 325px; */
  }
  
  .heightCustomerNote {
    height: 30vh;
    margin-top: 20px;
    width: 30%;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1124px) {
  .heightCustomerSearchBig {
    min-height: 431px;
    height: max-content;
    margin-top: 20px;
    width: 50%;
  }

  .heightCustomerInfo {
    height: inherit;
    margin-top: 20px;
    width: 40% !important;
    margin-right: 45px;
    /* min-width: 325px; */
  }

  .searchConsumer {
    width: 76vw;
  }

  .viewCustomer > .table-container {
    overflow-x: auto;
    width: 45vw;
  }

  .titleTableViewCustomers {
    width: 45vw;
  }

  .widthPaginationConsumers {
    width: 45vw;
  }

  .boxNieuwClient {
    margin-right: 0px !important;
  }

  .heightCustomerNote {
    height: 30vh;
    margin-top: 20px;
    width: 35%;
    margin-right: 48px;
  }

  .makeCustomer {
    display: flex;
     flex-direction: column !important;
     align-items: center !important;
  }

  .newClientNote {
    width: 53vw !important;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 975px) {
  .customerSearchInfo {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    position: relative;
    right: 5vw;
  }

  .saldoShowers {
    width: 20vw;
  }

  .tableNote {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    position: relative;
    right: 5vw;
  }

  .searchConsumer {
    margin-left: 25px;
    width: 93.5%;
  }

  .heightCustomerSearchBig {
    min-height: 431px;
    height: max-content;
    margin-top: 20px;
    width: 80%;
  }

  .heightCustomerInfo {
    height: inherit;
    margin-top: 20px;
    width: 80% !important;
    margin-right: 5px !important;
  }

  .viewCustomer > .table-container {
    position: relative;
    right: 5.1vw;
    overflow-x: auto;
    width: 64vw;
  }

  .titleTableViewCustomers {
    width: 64vw;
    position: relative;
    right: 5.1vw;
    z-index: 2;
  }

  .widthPaginationConsumers {
    width: 64vw;
    position: relative;
    right: 5.1vw;
  }

  .widthTablePunten {
    margin-bottom: 0px !important	;
  }

  .heightCustomerNote {
    height: 30vh;
    margin-top: 20px;
    width: 80%;
    margin-right: 0px !important;
  }
}

@media only screen and (max-width: 870px) {
  .streetHouseNum {
    display: flex;
    flex-direction: column !important;
  }

  .widthStreetInputs {
    width: 42vw;
  }

  .businesNumbers {
    display: flex;
    flex-direction: column !important;
  }

  .twoInputFieldWidth {
    width: 42vw !important;
  }
}

@media only screen and (max-height: 850px) {
  .heightCustomerNote {
    margin-bottom: 30px;
  }
}

@media only screen and (max-height: 655px) {
  .heightCustomerNote {
    margin-bottom: 70px;
  }

  .textareaStyle {
    height:19vh;
  }
}

