.backgroundColor {
    background: linear-gradient(90deg, rgba(253,253,253,1) 0%, rgb(192, 192, 192) 100%) !important;
    height: 100vh;
    position: relative;
}

.formContainer {
    position: relative;
    top: 160px;
}

.inputStyle {
    height: 60px;
    width: 40vw;
    padding-left: 30px;
}

.inputStyle::placeholder {
    font-size: larger;
    font-weight: 600;
    color: #aaaaaa;
}

.loginButton {
    background-color: #0bc0d5;
    color: white;
    font-weight: 600;
    height: 60px;
    font-size: larger !important;
}

.loginButton:hover, .loginButton:active {
    color: white !important;
    background-color: #0aa9bb !important;
    outline: none !important;
}
