.heightTableCustomers {
  margin-top: 20px;
  width: 80vw;
  position: relative;
  height: fit-content;
}

.actionDropdown {
  border: none;
  padding: 5px;
  width: 130px !important;
  height: 35px;
  border-radius: 30px;
  border-right: 9px solid #00000000;
}

.optionsSelect {
  display: flex;
  text-align: start;
  border-right: 6px solid #00000000;
  font-weight: 600;
}

.iconSearch {
  height: 17px;
  width: 17px;
  position: relative;
  right: 30px;
}

.SearchFieldWidth {
  width: 20vw !important;
  height: 35px;
  border: none;
}

.SearchFieldWidth::placeholder {
  font-weight: 600;
  color: black;
}
.customerTable > .table-container > table.dataTable > thead.customThead > tr > th:first-child  {
  width: 20px !important;
  margin: 0px !important;
}

.customerTable > .table-container > table.dataTable > tbody.customTbody > tr  {
  height: 70px !important;
}

.customerTable > .table-container > table.dataTable > thead.customThead > tr > th:nth-child(3), th:nth-child(4), th:nth-child(5)  {
  text-align: center;
  width: 20%;
  white-space: nowrap;
}

/* .customerTable > .table-container > table.dataTable > thead.customThead > tr > th:nth-child(2), th:nth-child(3)  {
  width: 200px;
} */

.customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:first-child  {
  width: 0.1% !important;
  margin: 0px !important;
  padding-bottom: 20px !important;
}

.customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5)  {
  width: 15%;
}


.customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(2) > a  {
  color: #0bc0d5 !important;
  text-decoration: none;
}

.customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(2)  {
  overflow-x: auto !important;
}

.customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:nth-child(3), td:nth-child(4), td:nth-child(5)  {
  text-align: center;
}

.customerTable > .table-container {
  padding-left: 10px;
}



.buttonsAddCustomers {
  background-color: #0bc0d5;
  border: none;
  border-radius: 20px;
}

.buttonsAddCustomers:hover, .buttonsAddCustomers:active {
  background-color: #0cabbd !important;
  border: none;
  border-radius: 20px;
}

.parentBox {
  width: 85vw !important;
  padding-left: 1.2rem !important;
}




@media only screen and (max-width: 1275px) { 
  /* .customerTable > .table-container {
    overflow-x: auto;
  } */

  .customerTable > .table-container > table.dataTable  {
    width: 70vw !important;
    /* white-space: nowrap; */
  }

  .parentBox {
    width: 80vw !important;
    padding-left: 1.2rem !important;
  }

  .customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:first-child  {
    width: 1% !important;
    margin: 0px !important;
    padding-bottom: 20px !important;
  }

}

@media only screen and (max-width: 1080px) { 
  .customerTable > .table-container {
    width: 75vw !important;
  }

  .widthPagination {
    width: 75vw !important;
  }
  
  .navigationItemsTable {
    width: 75vw;
  }

  .customerTable > .table-container > table.dataTable > tbody.customTbody > tr > td:first-child  {
    width: 1% !important;
    margin: 0px !important;
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 944px) { 

  .addCustomerParent {
    display: flex;
    flex-direction: column;
    justify-content: end !important;
  }

  .buttonsAddCustomers:first-child {
    margin-right: 0px !important;
    margin-bottom: 1rem;
  }

  .actionSearchParent {
    display: flex;
    flex-direction: column !important;
  }

  .actionDropdown {
    margin-bottom: 1rem;
  }

  .customerTable > .table-container  {
    overflow-x: auto;
    /* white-space: nowrap; */
  }
}

@media only screen and (max-width: 860px) { 

  .customerTable > .table-container {
    width: 72vw !important;
  }

  .widthPagination {
    width: 72vw !important;
  }
  
  .navigationItemsTable {
    width: 72vw;
  }

  .parentBox {
    padding-left: 2vw !important;
    padding-right: 2vw !important;
  }
}

@media only screen and (max-width: 662px) {
  .parentBox {
    padding-left: 40px !important;
  }
}
