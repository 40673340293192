.sidebar {
    position: fixed;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    min-width: 12vw;
}

.widthSidebar {
    width: 12vw;
}

.logo {
    height: 10vh;
    width: 10vw;
}

.iconsSidebar {
    width: 23px;
    height: 23px;
    position: relative;
    bottom: 5px;
}

.icon {
  width: 20px;
  height: 20px;
  color: rgb(0, 0, 0);
}

.icon-text {
    color: rgb(0, 0, 0);
    font-weight: 500;
}

.sidebar > div:nth-of-type(2) > div:last-child {
    position: fixed;
    bottom: 0%;
}

.siteLogo {
    margin-top: 10px;
    max-height: 13vh;
    max-width: 10vw !important;
}



@media only screen and (max-width: 768px) {
    .icon-text {
        display: none;
    }
    .icon-container {
        display: flex;
        justify-content: center;
    }
    .iconsSidebar {
        width: 40px !important;
        height: 40px !important;
        position: relative;
        bottom: 5px;
    }

    .sidebar > div:nth-of-type(2) > div:last-child {
        position: absolute;
        left: 10px;
    }

}

@media only screen and (max-width: 932px) {
    .iconsSidebar {
        width: 23px;
        height: 23px;
        position: relative;
        bottom: 4px !important;
    }

    .icon-text {
        font-size: xx-small !important;
        margin-top: 0.40rem !important;
    }

}

@media only screen and (max-width: 1150px) {
    .iconsSidebar {
        width: 23px;
        height: 23px;
        position: relative;
        bottom: 8px;
    }

    .icon-text {
        padding-top: 0px ;
        font-size: small;
    }

}
