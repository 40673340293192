.bgColor {
    background: linear-gradient(90deg, rgb(160, 160, 160) 0%, rgb(204, 204, 204) 100%) !important;
    height: 100vh;
}

.titleAdminPage {
    position: relative;
    bottom: 90px;
    color: rgb(255, 255, 255);
}

.hide {
    display: none;
  }
  
.btnAdmin {
    background-color: rgb(150, 150, 150);
    color: white !important;
    border-radius: 50px !important;
    border: none;
}

.btnAdmin:hover, .btnAdmin:focus, .btnAdmin:active {
    background-color: rgb(134, 134, 134) !important;
    outline: none !important;
    color: white !important;
    border: none !important;

}
  
.btn-large, .btn-large:hover , .btn-large:focus, .btn-large:active {
    padding: 11px 19px;
    font-size: 17.5px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.divParent {
    position: relative;
    top: 15vh;
}

.inputStyleAdmin {
    height: 60px;
    width: 30vw;
}

.logoAdmin {
    max-width: 25vw;
    max-height: 18vw;
}