.navigationBarSettings {
  height: 85vh;
  width: 20vw;
  min-width: fit-content;
  margin-top: 30px;
  margin-right: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.settingsBox {
  height: 85vh;
  margin-top: 30px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  width: 80vw;
}

.arrowsInput {
  height: 20px;
  width: 60px;
  text-align: center;
  line-height: 3px;
  background-color: white;
}

.arrowsInput:hover {
  background-color: rgb(209, 209, 209);
}

.iconUp {
  position: relative;
  top: 6px;
  color: black;
  font-size: small !important;
}

.iconDown {
  position: relative;
  bottom: 0px;
  color: black;
  font-size: small !important;
}

.arrowDown {
  border-bottom-right-radius: 30px;
  border: 2px solid rgb(239 239 239);
  border-top: none;
  border-left: none;
}

.arrowUp {
  border-top-right-radius: 30px;
  border: 2px solid rgb(239 239 239);
  border-bottom: none;
  border-left: none;
}

.profileBox {
  height: 85vh;
  margin-top: 30px;
  width: 80vw;
}

.parentBoxSettings {
 width: 80vw;
}

.inputFieldsPoints {
  padding-right: 10px;
  width: 22vw;
  height: 40px;
}

.inputFieldsPoints::-webkit-inner-spin-button {
  opacity: 0;
}

.companyInfo {
  width: 45vw !important;
}

@media only screen and (max-width: 1025px) {
  .settingsBox {
    width: 50vw !important;
  }

  .profileBox {
    height: 85vh;
    margin-top: 30px;
    width: 75vw;
  }
}

@media only screen and (max-width: 650px) {
  .profileBox {
    height: 85vh;
    margin-top: 30px;
    width: 70vw;
  }
}